<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/files-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				<template v-if="typeText">
					{{ typeText }}
				</template>
				<template v-else> Files </template>
			</v-flex>
			<v-flex class="text-right">
				<v-btn
					v-on:click="add_document_dialog = true"
					color="blue darken-4 text-white"
					class="ml-2"
					depressed
					tile
					><v-icon small left>mdi-playlist-plus</v-icon>Attach File</v-btn
				>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 345px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th class="p-2 light-blue-bg" width="50"></th>
						<th class="p-2 light-blue-bg">Name</th>
						<th class="p-2 light-blue-bg">Dates</th>
						<th class="p-2 light-blue-bg" colspan="2">Remarks</th>
					</tr>
				</thead>
				<tbody v-if="dbFiles.length">
					<tr v-for="(row, index) in dbFiles" :key="index">
						<td class="p-2 border-top-light-grey" width="50">
							<ImageTemplate style="max-width: 50px; width: 50px"></ImageTemplate>
						</td>
						<td class="p-2 border-top-light-grey">
							<p class="m-0 blue--text font-level-1">{{ row.name }}</p>
							<span class="text-muted font-small">{{ row.added_at }} by {{ row.created_by }}</span>
						</td>
						<td class="p-2 border-top-light-grey">
							<template v-if="row.start_date && row.end_date">
								<p><b>Start Date : </b> {{ row.start_date }}</p>
								<p><b>End Date : </b> {{ row.end_date }}</p>
							</template>
							<template v-else> No Dates </template>
						</td>
						<td class="p-2 border-top-light-grey font-level-1" width="50%">
							<v-layout>
								<v-flex md11>
									<template v-if="row && row.description && row.description != 'null'">
										{{ row.description }}
									</template>
									<template v-else> No Remarks </template>
								</v-flex>
								<v-flex md1> {{ getFileSize(row.size) }} KB </v-flex>
							</v-layout>
						</td>
						<td class="p-2 border-top-light-grey font-level-1" width="100">
							<v-btn
								v-on:click="doAction(row, 'download')"
								small
								icon
								depressed
								color="blue darken-4"
								class="mr-2"
								><v-icon small>mdi-download</v-icon></v-btn
							>
							<v-btn small v-on:click="doAction(row, 'delete')" icon depressed color="red lighten-1"
								><v-icon small>mdi-delete</v-icon></v-btn
							>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="4">
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no file at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<Dialog :dialog.sync="add_document_dialog">
			<template v-slot:title> Upload Document </template>
			<template v-slot:body>
				<v-form
					ref="documentForm"
					v-model.trim="export_valid"
					lazy-validation
					v-on:submit.stop.prevent="submitDocument()"
				>
					<table style="table-layout: fixed" class="table-document" width="100%">
						<template v-for="(file, index) in files">
							<tr :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
								<td width="35%" class="px-2">
									<v-file-input
										:id="`document-file-${index}`"
										placeholder="Select File"
										outlined
										class="mt-3"
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-model="file.file"
										v-on:change="updateFile(index, $event)"
										:rules="[vrules.requiredFile(file.file, `File ${index + 1}`)]"
										:class="{ required: !file.file }"
										v-on:click:clear="updateFile(index, $event)"
									></v-file-input>
								</td>
								<td width="35%" class="px-2">
									<TextInput
										:id="`document-name-${index}`"
										:class="{ required: !file.name }"
										:rules="[vrules.required(file.name, `Name ${index + 1}`)]"
										v-model="file.name"
										hide-details
										placeholder="File Name"
										:suffix="file.suffix"
									></TextInput>
								</td>
								<td width="10%" align="center" valign="middle" class="px-2" rowspan="3">
									<v-btn
										:disabled="files.length < 2"
										v-on:click="removeFile(index)"
										color="red lighten-1 white--text"
										class="mt-3"
										icon
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</td>
							</tr>
							<tr :key="`file-date-${index}`" :class="{ 'table-alternate-row': index % 2 }">
								<td width="45%" class="px-2">
									<DatePicker
										placeholder="Start Date"
										id="export-from-date"
										v-model="file.start_date"
									></DatePicker>
								</td>
								<td width="45%" class="px-2">
									<DatePicker
										placeholder="End Date"
										id="export-from-date"
										v-model="file.end_date"
										:min-date="file.start_date"
									></DatePicker>
								</td>
							</tr>
							<tr :key="`file-remark-${index}`" :class="{ 'table-alternate-row': index % 2 }">
								<td colspan="2" width="90%" class="px-2 py-3">
									<TextAreaInput
										:rows="2"
										dense
										:id="`document-remark-${index}`"
										hide-details
										v-model="file.remark"
										placeholder="Enter remark..."
										:disabled="attachLoading"
										:loading="attachLoading"
									></TextAreaInput>
								</td>
							</tr>
						</template>
					</table>
					<v-btn color="blue darken-4 white--text" class="mt-2" tile depressed v-on:click="addMore()">
						<v-icon>mdi-plus</v-icon> Add More...
					</v-btn>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="!export_valid || attachLoading"
					:loading="attachLoading"
					class="white--text"
					depressed
					color="blue darken-4"
					tile
					v-on:click="submitDocument()"
				>
					Submit
				</v-btn>
				<v-btn
					depressed
					tile
					:disabled="attachLoading"
					:loading="attachLoading"
					v-on:click="add_document_dialog = false"
				>
					Close
				</v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="deleteDialog" :dialog-width="600">
			<template v-slot:title> Delete File</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="10" class="py-0 my-auto">
						<p class="btx-p m-0">Deleting File is irreversible, Are you sure about deleting it?</p>
					</v-col>
				</v-row>
			</template>
			<template v-slot:action>
				<v-btn
					class="white--text"
					:loading="deleteLoading"
					:disabled="deleteLoading"
					depressed
					color="red lighten-1"
					tile
					v-on:click="deleteFile()"
				>
					Yes! Delete
				</v-btn>
				<v-btn depressed tile :disabled="deleteLoading" v-on:click="deleteDialog = false">
					No, Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { toNumber, round } from "lodash";
import ImageTemplate from "@/view/components/Image";
import Dialog from "@/view/components/Dialog";
import ApiService from "@/core/services/api.service";
import { EventBus } from "@/core/event-bus/event.bus";
import DatePicker from "@/view/components/DatePicker";
import { SET_ERROR } from "@/core/services/store/common.module";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";

export default {
	props: {
		typeText: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		typeId: {
			type: Number,
			default: null,
		},
		reload: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		reload(param) {
			if (param) {
				this.getFiles();
			}
		},
	},
	data() {
		return {
			pageLoading: true,
			deleteLoading: false,
			deleteDialog: false,
			attachDialog: false,
			attachLoading: false,
			uuid: null,
			description: null,
			files: [
				{
					file: null,
					name: null,
					remark: null,
					suffix: null,
				},
			],
			dbFiles: [],
			add_document_dialog: false,
			export_valid: true,
		};
	},
	methods: {
		init() {
			this.deleteLoading = false;
			this.deleteDialog = false;
			this.attachDialog = false;
			this.attachLoading = false;
			this.uuid = null;
			this.description = null;
			this.files = [
				{
					file: null,
					name: null,
					remark: null,
					suffix: null,
					start_date: null,
					end_date: null,
				},
			];
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
				remark: null,
				suffix: null,
				start_date: null,
				end_date: null,
			});
		},
		submitDocument() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.documentForm);

			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.documentForm.validate()) {
				return false;
			}

			const params = new FormData();

			for (let i = 0; i < this.files.length; i++) {
				params.append(`file[${i}][file]`, this.files[i].file);
				params.append(`file[${i}][name]`, this.files[i].name);
				if (this.files[i].remark) {
					params.append(`file[${i}][remark]`, this.files[i].remark);
				}
				if (this.files[i].start_date && this.files[i].end_date) {
					params.append(`file[${i}][start_date]`, this.files[i].start_date);
					params.append(`file[${i}][end_date]`, this.files[i].end_date);
				}
			}

			params.append("type_id", this.typeId);
			params.append("type", this.type);

			this.attachLoading = true;

			ApiService.setHeader();
			ApiService.upload("all-documents", params)
				.then(() => {
					this.add_document_dialog = false;
					this.files = [
						{
							file: null,
							name: null,
							remark: null,
							suffix: null,
							start_date: null,
							end_date: null,
						},
					];
					this.getFiles();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.attachLoading = false;
				});
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		updateFiles(param) {
			if (param) {
				const max_size = toNumber(this.$uploadSizeLIMIT);
				const max_file = toNumber(this.$uploadLIMIT);
				for (let i = 0; i < param.length; i++) {
					const size = round(toNumber(param[i].size / 1024), 2);
					if (size <= max_size && this.files.length < max_file) {
						this.files.push(param[i]);
					}
				}
			}
		},
		getFileSize(size) {
			return round(toNumber(size) / 1024, 2);
		},
		removeFile(index) {
			this.files.splice(index, 1);
			this.$refs["upload-file"].value = null;
		},
		maxFileSize() {
			return round(toNumber(this.$uploadSizeLIMIT) / 1024, 2);
		},
		selectFile() {
			this.$refs["upload-file"].reset();
			this.$refs["upload-file"].$refs["input"].value = null;
			this.$nextTick(() => {
				this.$refs["upload-file"].$refs["input"].click();
			});
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.uuid = row.uuid;
					this.deleteDialog = true;
					break;
			}
		},
		deleteFile() {
			if (!this.uuid) {
				return false;
			}
			this.deleteLoading = true;
			ApiService.setHeader();
			ApiService.delete(`${this.type}/${this.typeUuid}/file/${this.uuid}`)
				.then(() => {
					this.getFiles();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.deleteLoading = false;
				});
		},
		getFiles() {
			ApiService.setHeader();
			ApiService.get(`${this.type}/${this.typeUuid}/file`)
				.then(({ data }) => {
					this.dbFiles = data;
					this.init();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		attachFile() {
			const params = new FormData();

			for (let i = 0; i < this.files.length; i++) {
				params.append(`file[${i}]`, this.files[i]);
			}

			params.append("description", this.description);

			this.attachLoading = true;

			ApiService.setHeader();
			ApiService.upload(`${this.type}/${this.typeUuid}/file`, params)
				.then(() => {
					this.getFiles();
					this.$refs["upload-file"].value = null;
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.attachLoading = false;
				});
		},
	},
	components: {
		Dialog,
		TextInput,
		ImageTemplate,
		DatePicker,
		TextAreaInput,
	},
	mounted() {
		this.getFiles();
		EventBus.$on("reload:asset", () => {
			this.getFiles();
		});
	},
};
</script>
