<template>
	<v-dialog
		origin="center center"
		v-model="barcodeDialog"
		persistent
		scrollable
		content-class="common-dialog"
		:max-width="500"
	>
		<v-card>
			<v-card-title
				class="headline dialog-custom-header text-uppercase custom-border-bottom custom-header-blue-text white-background"
			>
				{{ title }}
			</v-card-title>
			<v-card-text>
				<div class="text-center mt-2">
					<img class="mb-4" :src="barcodeImage" :alt="barcodeText" width="400px" />
					<h3>{{ barcodeText }}</h3>
				</div>
			</v-card-text>
			<v-divider class="my-0"></v-divider>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn depressed tile v-on:click="$emit('close', true)">Close </v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	name: "barcode-dialog",
	props: {
		barcodeImage: {
			type: String,
			default: null,
		},
		title: {
			type: String,
			default: null,
		},
		barcodeText: {
			type: String,
			default: null,
		},
		barcodeDialog: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
