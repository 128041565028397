<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md12 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				Warranties
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="5%" class="p-2 light-blue-bg">Action</th>
						<th width="10%" class="p-2 light-blue-bg">Image</th>
						<th width="15%" class="p-2 light-blue-bg">Warranty Info</th>
						<th width="10%" class="p-2 light-blue-bg">Barcode</th>
						<th width="15%" class="p-2 light-blue-bg">Equipment Info</th>
						<th width="20%" class="p-2 light-blue-bg">Warranty</th>
						<th width="10%" class="p-2 light-blue-bg">Status</th>
						<th width="15%" class="p-2 light-blue-bg">Created At</th>
					</tr>
				</thead>
				<tbody v-if="warranties.length">
					<tr
						v-for="(row, index) in warranties"
						:key="index"
						class="cursor-pointer"
						v-on:click="getWarrantyDetail(row.uuid)"
					>
						<td class="p-2 border-top-light-grey">
							<v-tooltip v-if="false" top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										fab
										x-small
										color="green"
										v-bind="attrs"
										v-on="on"
										class="mr-3"
										v-on:click.stop.prevent="routeToUpdate(row.uuid)"
									>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>
								</template>
								<span>Edit Sub-Part</span>
							</v-tooltip>
							<v-tooltip top content-class="custom-top-tooltip">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										depressed
										fab
										x-small
										color="red"
										text-color="white"
										v-bind="attrs"
										v-on="on"
										v-on:click.stop.prevent="deleteConfirm(row)"
									>
										<v-icon color="white">mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Delete Sub-Part</span>
							</v-tooltip>
						</td>
						<td class="p-2 border-top-light-grey">
							<ImageTemplate circle style="max-width: 50px" :src="row.image"> </ImageTemplate>
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="d-flex align-items-center">
								<div class="ml-2">
									<div>
										<label class="font-size-14 mb-1"
											><b>
												<v-icon size="18">mdi-pound</v-icon>
											</b></label
										>
										{{ row.warranty_barcode }}
									</div>

									<div v-if="row.warranty_cost">
										<label class="mb-1 bold-600">Cost : </label>
										{{ row.warranty_cost }}
									</div>
								</div>
							</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<div v-on:click.stop.prevent="openPopup(row)" class="text-center">
								<label class="font-size-14 mb-1">
									<v-icon size="30">mdi-barcode-scan</v-icon>
								</label>
							</div>
							<!-- <ImageTemplate circle style="max-width: 50px" :src="td.barcode_image"></ImageTemplate> -->
						</td>
						<td class="p-2 border-top-light-grey">
							<div class="d-flex align-items-center">
								<div class="ml-2">
									<div>
										<label class="font-size-14 mb-1"
											><b>
												<v-icon size="18">mdi-pound</v-icon>
											</b></label
										>
										{{ row.asset_barcode }}
									</div>

									<div style="width: 250px; white-space: break-spaces">
										<label class="mb-1 bold-600">Name : </label>
										<span class="bold-500">{{ row.asset_name }}</span>
									</div>
									<div v-if="row.group" style="width: 250px; white-space: break-spaces">
										<label class="mb-1 bold-600">Type : </label>
										{{ row.type }}
									</div>
								</div>
							</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<div v-if="row.warranty_start_date" class="d-flex align-items-center">
								<div
									:class="`ml-2 ${
										row.isWarrantyExpired == 1 ? 'expired' : row.isWarrantyRenewed == 1 ? 'renewed' : ''
									}`"
								>
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="cyan" content="S" class="badge-new"></v-badge>
												</label>
											</template>
											<span> Start Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.warranty_start_date }}
										</v-chip>
									</div>

									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="red" content="E" class="badge-new"></v-badge>
												</label>
											</template>
											<span> End Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.warranty_end_date }}
										</v-chip>
									</div>
									<div>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<label v-bind="attrs" v-on="on" class="font-size-14 mb-1 mr-7">
													<v-badge color="orange" content="R" class="badge-new"></v-badge>
												</label>
											</template>
											<span> Reminder Date </span>
										</v-tooltip>
										<v-chip label class="mb-2">
											{{ row.warranty_reminder_date }}
										</v-chip>
									</div>
								</div>
							</div>
							<div v-else>
								<span class="text-truncate" v-html="'No Warranty Data'"> </span>
							</div>
						</td>
						<td class="p-2 border-top-light-grey">
							<v-chip
								:color="statusObject(row.status_value) ? `${statusObject(row.status_value).color}3e` : ''"
								:text-color="statusObject(row.status_value) ? statusObject(row.status_value).color : ''"
								:style="`border:1px solid ${
									statusObject(row.status_value) ? statusObject(row.status_value).color : ''
								}`"
								label
							>
								{{ statusObject(row.status_value) ? statusObject(row.status_value).text : "" }}
							</v-chip>
						</td>
						<td class="p-2 border-top-light-grey">
							<div>
								<label class="mb-1 bold-600 mr-1">At : </label>
								<TableDateTime
									v-if="row.created_at"
									:human-format="row.created_at"
									:system-format="row.added_at"
								></TableDateTime>
							</div>
							<div v-if="row.created_by">
								<label class="mb-1 bold-600 mr-1">By : </label>
								<v-icon size="18">mdi-account-circle </v-icon>
								{{ row.created_by }}
							</div>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="9">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no Sub-Parts at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>
</template>
<script>
import { toSafeInteger } from "lodash";
import ApiService from "@/core/services/api.service";
import ImageTemplate from "@/view/components/Image";
import TableDateTime from "@/view/components/TableDateTime";
import { find } from "lodash";

export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "all",
		},
		typeData: {
			type: Object,
			default: null,
		},
		isParentRetired: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			statusList: [],
			status_filter: "checked-out",
			warranties: [],
			reservation: {},
			checkoutDialog: false,
			cancelDialog: false,
			pageLoading: true,
			approveLoading: false,
			cancelLoading: false,
			addDialog: false,
			uuid: null,
			typeId: 0,
			typeUuid: null,
			detailDialog: false,
			deleteDialog: false,
			deleteUUID: null,
			deleteText: null,
			deleteButton: false,
		};
	},
	methods: {
		statusObject(status) {
			return find(this.statusList, { value: status });
		},
		openPopup(data) {
			this.pico_barcode_imgae = data.barcode_image;
			this.pico_barcode_text = data.pico_barcode;
			this.barcodeDialogTitle = `${data.name ? data.name : ""} (#${data.barcode})`;
			this.barcodeDialog = true;
			return false;
		},
		deleteConfirm({ uuid, barcode }) {
			this.deleteDialog = true;
			this.deleteUUID = uuid;
			this.deleteText = barcode;
		},
		getWarrantyDetail(uuid) {
			this.$router.push({
				name: "detail-warranty",
				params: { uuid: uuid },
				query: { t: new Date().getTime() },
			});
		},
		downloadFile(row) {
			window.open(row.signed_pdf, "_blank");
		},
		doAction(row, param) {
			this.reservation = row;
			switch (param) {
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "cancel":
					this.cancelDialog = true;
					break;
			}
		},
		getWarranties() {
			ApiService.query(`warranties/${this.type}/${this.typeUuid}`)
				.then(({ data }) => {
					this.warranties = data.rows;
					this.statusList = data.status;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		ImageTemplate,
		TableDateTime,
	},
	mounted() {
		this.typeUuid = this.typeData.uuid;
		this.getWarranties();
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
