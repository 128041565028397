<template>
	<div>
		<CreateDialog :dialog="dialog" v-on:close="$emit('close', true)" :back="false">
			<template v-slot:title>{{ type }}</template>
			<template v-slot:body>
				<v-layout class="align-center light-blue-bg px-4 py-2">
					<v-flex md12>
						<v-layout class="justify-content-between align-items-center">
							<v-flex md5>
								<p class="my-auto font-level-3-bold">
									<span class="fw-500">
										<b class="text-truncate d-inline-block" style="max-width: 42%; vertical-align: middle">
											<ShowValue :object="subPart" object-key="name" label="subPart name"></ShowValue>
										</b>
										<v-chip color="cyan" class="ml-2 mr-2" label text-color="white"
											>{{ subPart.barcode }}
										</v-chip>
										<template v-if="subPart.is_fault == 1">
											<v-chip color="#1bc5bd" text-color="white" label class="mr-2"> Fault </v-chip>
										</template>
										<template v-if="subPart.retired == 1">
											<v-chip color="#F44336" text-color="white" label> RETIRED </v-chip>
										</template>
										<template v-else>
											<template v-if="subPart.status == 0">
												<v-chip color="#F44336" text-color="white" label> INACTIVE </v-chip>
											</template>
											<template v-else>
												<template v-if="subPart.status == 1">
													<v-chip color="#4CAF50" text-color="white" label> ACTIVE </v-chip>
												</template>
											</template>
										</template>
									</span>
								</p>
							</v-flex>
							<v-flex md7 class="text-right">
								<template>
									<v-menu offset-y left bottom content-class="elevation-3" rounded="0">
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="blue darken-4 text-white"
												v-on="on"
												v-bind="attrs"
												class="mr-2"
												depressed
												tile
											>
												<v-icon small left>mdi-more mdi-flip-h</v-icon>More
											</v-btn>
										</template>

										<v-list>
											<v-list-item v-if="getPermission('asset:update')" v-on:click="doAction('edit')">
												<v-list-item-title>
													<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
													Edit
												</v-list-item-title>
											</v-list-item>
											<v-list-item v-if="getPermission('asset:delete')" v-on:click="doAction('delete')">
												<v-list-item-title>
													<v-icon small left color="blue darken-4">mdi-delete</v-icon>
													Delete
												</v-list-item-title>
											</v-list-item>
											<!-- This is used in responsive -->
											<template>
												<template v-if="parentDetails && parentDetails.has_checkout != 1">
													<template>
														<v-list-item v-if="getPermission('asset:update')" v-on:click="doAction('retire')">
															<v-list-item-title>
																<v-icon small left color="blue darken-4">mdi-cancel</v-icon>
																Retire
															</v-list-item-title>
														</v-list-item>
													</template>
												</template>
											</template>
										</v-list>
									</v-menu>
									<v-menu
										v-if="
											getPermission('asset:cretae') &&
											!isParentRetired &&
											parentDetails &&
											parentDetails.has_checkout != 1
										"
										offset-y
										left
										bottom
										content-class="elevation-3"
										rounded="0"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												color="blue darken-4 text-white"
												v-on="on"
												v-bind="attrs"
												class="mx-2"
												depressed
												tile
											>
												<v-icon small left>mdi-hammer-wrench</v-icon>Service
											</v-btn>
										</template>

										<v-list>
											<v-list-item v-on:click="doAction('start-service')">
												<v-list-item-title>
													<v-icon small left color="blue darken-4">mdi-clock-check-outline</v-icon>
													Start
												</v-list-item-title>
											</v-list-item>
											<v-list-item v-on:click="doAction('complete-service')">
												<v-list-item-title>
													<v-icon small left color="blue darken-4">mdi-format-list-checks</v-icon>
													Complete
												</v-list-item-title>
											</v-list-item>
											<v-list-item v-on:click="doAction('extend-service')">
												<v-list-item-title>
													<v-icon small left color="blue darken-4">mdi-share-all-outline</v-icon>
													Extend
												</v-list-item-title>
											</v-list-item>
											<v-list-item v-on:click="doAction('schedule-service')">
												<v-list-item-title>
													<v-icon small left color="blue darken-4">mdi-calendar-clock</v-icon>
													Schedule
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</template>
							</v-flex>
						</v-layout>
						<div class="d-flex mt-4">
							<div class="w-10 pr-3">
								<ImageTemplate circle :src="subPart.image"></ImageTemplate>
							</div>
							<div class="w-100 pr-3">
								<div v-if="subPart.pico_barcode && subPart.barcode_image" style="max-width: 300px">
									<div class="d-inline-block text-center">
										<img
											style="max-width: 100%; height: 30px"
											:src="subPart.barcode_image"
											:alt="subPart.pico_barcode"
										/>
										<pre class="mb-0 barcode-text bold-700">{{ subPart.pico_barcode }}</pre>
									</div>
								</div>
								<div class="d-flex">
									<div class="w-33 pl-2">
										<div class="mt-2">
											<span class="fw-600">Sub-Part # : </span>
											<span class="fw-500 no-wrap">
												<ShowValue :object="subPart" object-key="barcode" label="subPart #"></ShowValue>
											</span>
										</div>
									</div>
									<div class="w-33 pl-2">
										<div class="mt-2">
											<span class="fw-600">Manufacturing Date : </span>
											<span class="fw-500 no-wrap">
												<ShowValue
													:object="subPart"
													object-key="manufacturing_date_formatted"
													label="purchased date"
												>
												</ShowValue>
											</span>
										</div>
									</div>
									<div class="w-33 pl-2">
										<div class="mt-2">
											<span class="fw-600"> Contact for Item : </span>
											<span class="fw-500 no-wrap">
												<ShowValue :object="subPart" object-key="contact" label="contact for item"> </ShowValue>
											</span>
										</div>
									</div>
								</div>
								<div class="d-flex">
									<div class="w-33 pl-2">
										<div class="mt-2">
											<span class="fw-600 mr-2">Supplier : </span>
											<span class="fw-500">
												<span class="fw-500 no-wrap">
													<ShowValue
														:object="subPart"
														object-key="supplier_relation.display_name"
														label="supplier"
													></ShowValue>
												</span>
											</span>
										</div>
									</div>
									<div class="w-33 pl-2">
										<div class="mt-2">
											<span class="fw-600"> Category : </span>
											<span class="fw-500 no-wrap">
												<ShowValue :object="subPart" object-key="category_relation.name" label="Category">
												</ShowValue>
											</span>
										</div>
									</div>
									<div class="w-33 pl-2">
										<div class="mt-2">
											<span class="fw-600">Purchased Date : </span>
											<span class="fw-500 no-wrap">
												<ShowValue
													:object="subPart"
													object-key="purchased_date_formatted"
													label="purchased date"
												>
												</ShowValue>
											</span>
										</div>
									</div>
								</div>
								<div class="d-flex">
									<div class="w-33 pl-2">
										<div class="mt-2">
											<span class="fw-600">Cost Price : </span>
											<span class="fw-500 no-wrap">
												<ShowValue :object="subPart" object-key="cost_price_formatted" label="cost price">
												</ShowValue>
											</span>
										</div>
									</div>
									<div class="w-33 pl-2">
										<div class="mt-2">
											<span class="fw-600">Quantity : </span>
											<span class="fw-500 no-wrap">
												<ShowValue :object="subPart" object-key="quantity" label="quantity"> </ShowValue>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div v-if="false" class="w-20 pr-3">
								<template v-if="subPart.pico_barcode && subPart.barcode_image">
									<span class="fw-600">Pico Barcode : </span>
									<span class="fw-500">
										<Barcode circle :src="subPart.barcode_image"></Barcode>
									</span>
								</template>
							</div>
						</div>
					</v-flex>
				</v-layout>
				<v-tabs
					v-model="subpartTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#detail">
						<v-icon small left>mdi-format-list-bulleted-square</v-icon>

						Details
					</v-tab>
					<v-tab v-if="getPermission('service:view')" href="#serial_numbers" class="pr-8">
						<v-icon small left>mdi-calendar-check</v-icon>
						<v-badge color="cyan" :content="serialNoCounts"> Serial Numbers </v-badge>
					</v-tab>
					<v-tab v-if="getPermission('service:view')" href="#services" class="pr-8">
						<v-icon small left>mdi-hammer-wrench</v-icon>
						<v-badge color="cyan" :content="serviceCounts"> Services </v-badge>
					</v-tab>
					<v-tab v-if="getPermission('subPart:update')" href="#file" class="pr-8">
						<v-icon small left>mdi-file-document-multiple</v-icon>
						<v-badge color="cyan" :content="fileCounts"> Files </v-badge>
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="subpartTab">
					<v-tab-item value="detail">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Sub-Part Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<tr>
										<th width="250px">Warranty Start Date</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="warranty_start_date_formatted"
												label="warranty Start Date"
											></ShowValue>
										</td>
										<th width="250px">Warranty End Date</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="warranty_end_date_formatted"
												label="warranty End Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Warranty Reminder Date</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="warranty_reminder_date_formatted"
												label="warranty Reminder Date"
											></ShowValue>
										</td>
										<th width="250px">Warranty Reminder End Date</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="warranty_reminder_endDate_formatted"
												label="Warranty Reminder End Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Maintenance Start Date</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="maintenance_start_date_formatted"
												label="Maintenance Start Date"
											></ShowValue>
										</td>
										<th width="250px">Maintenance End Date</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="maintenance_end_date_formatted"
												label="Maintenance End Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Maintenance Reminder Date</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="maintenance_reminder_date_formatted"
												label="Maintenance Reminder Date"
											></ShowValue>
										</td>
										<th width="250px">Maintenance Reminder End Date</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="maintenance_reminder_endDate_formatted"
												label="Maintenance Reminder End Date"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Maintenance By</th>
										<td>
											<template v-if="subPart.maintenance_by">
												<ShowValue
													:object="subPart"
													object-key="maintenance_by_relation.display_name"
													label="Maintenance By"
												></ShowValue>
												(Supplier)
											</template>
											<template v-else>
												<ShowValue
													:object="subPart"
													object-key="member.display_name"
													label="Maintenance By"
												></ShowValue>
												(Inchagre Officer)
											</template>
										</td>
										<th width="250px">Cost of Maintenance</th>
										<td>
											<ShowValue
												:object="subPart"
												object-key="maintenance_cost_formatted"
												label="Cost of Maintenance"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Created On</th>
										<td colspan="3">
											<ShowValue
												:object="subPart"
												object-key="created_at"
												tooltip
												tooltip-value="added_at_formatted"
												label="created on"
											></ShowValue>
										</td>
									</tr>
									<tr>
										<th width="250px">Description</th>
										<td colspan="3">
											<ShowValue :object="subPart" object-key="description" label="description"> </ShowValue>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="serial_numbers">
						<div class="mx-4">
							<div class="overflow-y" style="max-height: calc(100vh - 250px)">
								<v-layout class="p-4 border-bottom-light-grey min-height-57px">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/asset-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										Serial Numbers Details
									</v-flex>
								</v-layout>
								<table width="100%" class="detail-table scattered-table">
									<thead>
										<tr>
											<th width="15%" class="p-2 light-blue-bg">Barcode</th>
											<th width="15%" class="p-2 light-blue-bg">Barcode Text</th>
											<th width="20%" class="p-2 light-blue-bg">Status</th>
											<th width="20%" class="p-2 light-blue-bg">Created By</th>
											<th width="20%" class="p-2 light-blue-bg">Created At</th>
										</tr>
									</thead>
									<tbody>
										<template v-if="subPart.serial_numbers">
											<tr v-for="(row, index) in subPart.serial_numbers" :key="index">
												<td>
													<div v-on:click.stop.prevent="openPopup(row)" class="cursor-pointer">
														<label class="font-size-14 mb-1">
															<v-icon size="30">mdi-barcode-scan</v-icon>
														</label>
													</div>
												</td>
												<td>
													<pre class="mb-0 barcode-text bold-700">{{ row.serial_no }}</pre>
												</td>
												<td>
													<v-chip color="green" text-color="white" label> Active </v-chip>
												</td>
												<td>
													<div v-if="row.created_by">
														<label class="font-size-14 mb-1"
															><b>
																<v-icon size="18">mdi-account-circle </v-icon>
															</b></label
														>
														{{ row.created_by.display_name }}
													</div>
												</td>
												<td>
													<TableDateTime
														v-if="row.created_at"
														:human-format="row.created_at"
														:system-format="row.added_at"
													></TableDateTime>
												</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</v-tab-item>
					<v-tab-item value="services"></v-tab-item>
					<v-tab-item value="file">
						<FilesWithDate
							v-if="subpartTab == 'file'"
							class="mx-4"
							type-text="Sub-Part Files"
							type="subpart"
							:type-uuid="uuid"
							:type-id="subPartId"
						>
						</FilesWithDate>
					</v-tab-item>
				</v-tabs-items>
			</template>
			<template v-slot:action>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
			</template>
		</CreateDialog>
		<Dialog :dialog="barcodeDialog" :dialog-width="400">
			<template v-slot:body>
				<div class="text-center">
					<img class="mb-4" :src="serial_no_image" :alt="serial_no" width="334px" />
					<h3>{{ serial_no }}</h3>
				</div>
			</template>

			<template v-slot:action>
				<v-btn depressed tile v-on:click="barcodeDialog = false">Close </v-btn>
			</template>
		</Dialog>
		<Dialog :dialog="barcodeDialog" :dialog-width="400">
			<template v-slot:body>
				<div class="text-center">
					<img class="mb-4" :src="serial_no_image" :alt="serial_no" width="334px" />
					<h3>{{ serial_no }}</h3>
				</div>
			</template>

			<template v-slot:action>
				<v-btn depressed tile v-on:click="barcodeDialog = false">Close </v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import ApiService from "@/core/services/api.service";
import FilesWithDate from "@/view/components/FilesWithDate";
import CreateDialog from "@/view/components/CreateDialog";
import ImageTemplate from "@/view/components/Image";
import ShowValue from "@/view/components/ShowValue";
import Dialog from "@/view/components/Dialog";
import TableDateTime from "@/view/components/TableDateTime";

export default {
	name: "service-template",
	title: "Service Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		dialog: {
			type: Boolean,
			default: false,
		},
		uuid: {
			type: String,
			default: null,
		},
	},
	watch: {
		uuid(param) {
			if (param) {
				this.getSubPartDetails(param);
			}
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			manageServiceTypeDialog: false,
			inventory: {},
			serviceUuid: null,
			subpartTab: null,
			subPart: {},
			serialNoCounts: "0",
			fileCounts: "0",
			serviceCounts: "0",
			serial_no_image: null,
			serial_no: null,
			barcodeDialog: false,
			subPartId: null,
			parentDetails: null,
			isParentRetired: false,
			dialogDetail: false,
		};
	},
	components: {
		FilesWithDate,
		CreateDialog,
		ImageTemplate,
		ShowValue,
		Dialog,
		TableDateTime,
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "retire":
					this.retireDialog = true;
					break;
				case "start-service":
					this.serviceDialog = true;
					break;
				case "schedule-service":
					this.serviceScheduleDialog = true;
					break;
				case "complete-service":
					this.serviceCompleteDialog = true;
					break;
				case "extend-service":
					this.serviceExtendDialog = true;
					break;
			}
		},
		openPopup(data) {
			this.serial_no_image = data.serial_no_image;
			this.serial_no = data.serial_no;
			this.barcodeDialog = true;
			return false;
		},
		getSubPartDetails(Uuid) {
			ApiService.get(`subpart/${Uuid}`)
				.then((res) => {
					this.subPart = res.data;
					this.subPartId = res.data.id;
					if (res.data.type == "asset") {
						this.parentDetails = res.data.asset;
						this.isParentRetired = this.parentDetails.retired == 1 ? true : false;
					}
					this.getSubPartCounts(res.data.id);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getSubPartCounts(subPartId) {
			ApiService.get(`subpart/${subPartId}/counts`)
				.then((res) => {
					let countsData = res.data;
					this.serialNoCounts = countsData.SubPartsSerialNo
						? countsData.SubPartsSerialNo.toString()
						: "0";
					this.fileCounts = countsData.SubPartsFile.toString();
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
