<template>
	<div>
		<CreateDialog :dialog="subPartDialog" v-on:close="$emit('close', true)" :back="false">
			<template v-slot:title>ADD SUB-PARTS</template>
			<template v-slot:body>
				<v-form
					ref="addPartForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="onSubmit"
				>
					<v-row v-if="subPartDialog" class="service-dialog">
						<v-col md="8">
							<v-row>
								<v-col md="12" class="py-0">
									<p class="middle-header-background mb-4 px-4">
										Core Information<br />
										<span>
											<v-icon small>mdi-progress-question</v-icon> Specify the primary description for your sub
											part
										</span>
									</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required">Name</label>
								</v-col>
								<v-col md="8" class="py-0">
									<TextInput
										hide-details
										placeholder="Name"
										v-model="subpart.name"
										:rules="[vrules.required(subpart.name, 'Name')]"
										:class="{
											required: !subpart.name,
										}"
									></TextInput>
								</v-col>

								<v-col md="4" class="my-auto py-0">
									<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required">Barcode</label>
								</v-col>
								<v-col md="8" class="py-0">
									<TextInput
										hide-details
										placeholder="Barcode"
										v-model="subpart.pico_barcode"
										:rules="[vrules.required(subpart.pico_barcode, 'Barcode')]"
										:class="{
											required: !subpart.pico_barcode,
										}"
									></TextInput>
								</v-col>

								<v-col md="4" class="my-auto py-0">
									<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
										>Quantity</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<v-text-field
										:loading="pageLoading"
										:disabled="pageLoading"
										hide-details
										outlined
										class="mt-3 pt-0"
										v-model="subpart.quantity"
										type="number"
										maxlength="13"
										step="1"
										min="0"
										size="13"
										placeholder="Quantity"
										v-on:change="manageQuantity"
									></v-text-field>
								</v-col>
								<template v-if="subpart.serial_nos.length > 0">
									<v-col md="12" class="my-auto py-0">
										<p class="middle-header-background mb-4 px-4">
											<span>{{ subpart.serial_nos.length }} Serial Numbers Autogenerated</span>
										</p>
									</v-col>
									<!-- <v-col md="12" v-for="(data, index) in subpart.serial_no" :key="index">
										<v-row>
											<v-col md="4" class="my-auto py-0">
												<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
													>Serial No {{ index+1 }}</label
												>
											</v-col>
											<v-col md="8" class="py-0">
												<TextInput
													hide-details
													:placeholder="`Serial No ${index+1}`"
													v-model="data.serial_no"
												></TextInput>
											</v-col>
										</v-row>
									</v-col> -->
								</template>

								<v-col md="4" class="my-auto py-0">
									<label for="group" class="btx-label mt-2 required">Category</label>
								</v-col>
								<v-col md="8" class="py-0">
									<AutoCompleteInput
										hide-details
										:items="categoryList"
										:disabled="pageLoading"
										:loading="pageLoading"
										id="group"
										:rules="[vrules.required(subpart.category, 'Category')]"
										:class="{
											required: !subpart.category,
										}"
										placeholder="Category"
										v-model="subpart.category"
										append-outer-icon="mdi-cog"
										v-on:click:append-outer="manageCategoryDialog = true"
									>
									</AutoCompleteInput>
								</v-col>

								<v-col md="4" class="my-auto py-0">
									<label for="contact" class="btx-label mt-2">Contact for Item</label>
								</v-col>
								<v-col md="8" class="py-0">
									<PhoneInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="contact"
										placeholder="Contact for Item"
										v-model="subpart.contact"
									>
									</PhoneInput>
								</v-col>

								<v-col md="4" class="py-0">
									<label :for="`return-on-${uniqFieldId}`" class="btx-label mt-3">Fault</label>
								</v-col>
								<v-col md="8" class="py-0">
									<RadioInput
										row
										hide-details
										v-model="subpart.fault"
										:disabled="pageLoading"
										:id="`service-performed-by-${uniqFieldId}`"
										:items="faultIteam"
									></RadioInput>
								</v-col>

								<v-col md="4" class="my-auto py-0">
									<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3">Warranty</label>
								</v-col>
								<v-col md="8" class="py-0">
									<v-checkbox
										dense
										hide-details
										class="mt-3 mb-0 p-0"
										:true-value="1"
										:false-value="0"
										v-model="subpart.warranty"
									></v-checkbox>
								</v-col>
								<template v-if="subpart.warranty">
									<v-col md="4" class="my-auto py-0">
										<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3">Warranty Type</label>
									</v-col>
									<v-col md="8" class="py-0">
										<NewRadioInput
											row
											hide-details
											v-model="subpart.warranty_type"
											:disabled="pageLoading"
											:id="`service-performed-by-${uniqFieldId}`"
											:items="warrantyType"
										></NewRadioInput>
									</v-col>
									<template v-if="subpart.warranty_type == 1">
										<v-col md="4" class="my-auto py-0">
											<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
												>Warranty Period</label
											>
										</v-col>
										<v-col md="8" class="py-0">
											<v-layout>
												<v-flex md6>
													<NumberInput
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														:id="`checkout-duration-${uniqFieldId}`"
														placeholder="Duration"
														v-model="subpart.warranty_duration"
														v-on:keyup="(e) => updateDuration(e, 'warranty_duration')"
													></NumberInput>
												</v-flex>
												<v-flex md6>
													<SelectInput
														hide-details
														:items="warrantyDurationList"
														:disabled="pageLoading"
														:loading="pageLoading"
														placeholder="Duration"
														v-model="subpart.warranty_duration_type"
														v-on:change="(e) => updateDuration(e, 'warranty_duration_type')"
													></SelectInput>
												</v-flex>
											</v-layout>
										</v-col>
										<v-col md="4" class="my-auto py-0">
											<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
												>Warranty Start Date</label
											>
										</v-col>
										<v-col md="8" class="py-0">
											<DatePicker
												placeholder="Start Date"
												id="export-from-date"
												v-model="subpart.warranty_start_date"
												:rules="[vrules.required(subpart.warranty_start_date, 'Warranty Start Date')]"
												:class="{
													required: !subpart.warranty_start_date,
												}"
												v-on:change="(e) => updateDuration(e, 'start_date')"
											></DatePicker>
										</v-col>

										<v-col md="4" class="my-auto py-0">
											<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3 required"
												>Warranty End Date</label
											>
										</v-col>
										<v-col md="8" class="py-0">
											<DatePicker
												placeholder="To"
												id="export-from-date"
												v-model="subpart.warranty_end_date"
												:rules="[vrules.required(subpart.warranty_end_date, 'Warranty End Date ')]"
												:class="{
													required: !subpart.warranty_end_date,
												}"
												v-on:change="checkWarrantyDiff"
											></DatePicker>
										</v-col>
										<v-col md="4" class="my-auto py-0">
											<label :for="`service-warranty-cost-${uniqFieldId}`" class="btx-label mt-3 required"
												>Warranty Cost</label
											>
										</v-col>
										<v-col md="8" class="py-0">
											<PriceInput
												hide-details
												:disabled="pageLoading"
												:loading="pageLoading"
												:id="`service-warranty-cost-${uniqFieldId}`"
												placeholder="Warranty Cost"
												v-model="subpart.warranty_cost"
											></PriceInput>
										</v-col>

										<v-col md="4" class="my-auto py-0">
											<label for="reminder-type" class="btx-label mt-2 required">Reminder Type</label>
										</v-col>
										<v-col md="8" class="py-0">
											<AutoCompleteInput
												dense
												id="reminder-type"
												hide-details
												v-model="subpart.reminder_type"
												placeholder="Select Reminder Type"
												:items="reminder_type_items"
												:disabled="pageLoading"
												:loading="pageLoading"
												item-text="text"
												item-value="value"
												v-on:keyup="get_reminder_values()"
											></AutoCompleteInput>
										</v-col>
										<v-col md="4" class="my-auto py-0">
											<label for="reminder-start-date" class="btx-label mt-2 required"
												><template v-if="subpart.reminder_type != 1">Start </template>Date</label
											>
										</v-col>
										<v-col md="8" class="py-0">
											<DatePicker
												dense
												id="reminder-start-date"
												hide-details
												v-model="subpart.reminder_start_date"
												:placeholder="subpart.reminder_type != 1 ? 'Select Start Date...' : 'Select Date...'"
												:disabled="pageLoading"
												:loading="pageLoading"
												v-on:keyup="get_reminder_values()"
												:min-date="formatDateRaw()"
												:rules="[
													vrules.required(
														subpart.reminder_start_date,
														subpart.reminder_type != 1 ? 'Start Date' : 'Date'
													),
												]"
											></DatePicker>
										</v-col>
										<template v-if="subpart.reminder_type != 1">
											<v-col md="4" class="my-auto py-0">
												<label for="reminder-frequency" class="btx-label mt-2 required">Frequency</label>
											</v-col>
											<v-col md="8" class="py-0">
												<TextInput
													dense
													id="reminder-frequency"
													hide-details
													type="number"
													v-model="subpart.reminder_frequency"
													placeholder="Enter Frequency..."
													:disabled="pageLoading"
													:loading="pageLoading"
													v-on:keyup="get_reminder_values()"
													:rules="[vrules.required(subpart.reminder_frequency, 'Frequency')]"
													class="mt-0"
												></TextInput>
											</v-col>
											<v-col md="4" class="my-auto py-0">
												<label for="reminder-end-date" class="btx-label mt-2 required">End Date</label>
											</v-col>
											<v-col md="8" class="py-0">
												<DatePicker
													dense
													:rules="[vrules.required(subpart.reminder_end_date, 'End Date')]"
													id="reminder-end-date"
													hide-details
													v-model="subpart.reminder_end_date"
													placeholder="Select End Date..."
													:disabled="pageLoading"
													:loading="pageLoading"
													readonly
												></DatePicker>
											</v-col>
										</template>
										<v-col md="4" class="my-auto py-0">
											<label for="attachment" class="btx-label mt-2">Attachment</label>
										</v-col>
										<v-col md="8" class="py-0">
											<template v-for="(data, index) in wtx_attachments">
												<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
													<v-col md="5" class="py-0">
														<v-file-input
															v-model="data.file"
															placeholder="Click here to select files"
															color="blue darken-4"
															multiple
															outlined
															class="mt-3"
															prepend-icon=""
															prepend-inner-icon="mdi-attachment"
															hide-details
															v-on:change="limitAttachmentWarranty($event, index)"
														>
														</v-file-input>
													</v-col>
													<v-col md="5" class="py-0">
														<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
													</v-col>
													<v-col md="1" class="py-0">
														<v-btn
															:disabled="wtx_attachments.length < 2"
															v-on:click="removeFileWarranty(index)"
															class="white--text mt-3"
															depressed
															color="red darken-4"
															tile
															style="margin-left: -10px"
														>
															<v-icon>mdi-delete</v-icon>
														</v-btn>
													</v-col>
													<v-col md="1" class="py-0">
														<v-btn
															:disabled="wtx_attachments.length > 2"
															v-on:click="addMoreWarranty()"
															class="white--text mt-3"
															depressed
															color="blue darken-4"
															tile
															style="margin-left: -13px"
														>
															<v-icon>mdi-plus</v-icon>
														</v-btn>
													</v-col>
												</v-row>
											</template>
										</v-col>
									</template>
								</template>
								<v-col md="4" class="py-0">
									<label :for="`description-${uniqFieldId}`" class="btx-label mt-3">Description</label>
								</v-col>
								<v-col md="8" class="py-0">
									<TextAreaInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`description-${uniqFieldId}`"
										placeholder="Description"
										v-model="subpart.description"
									></TextAreaInput>
								</v-col>
							</v-row>
							<v-row>
								<v-col md="12" class="py-0">
									<p class="middle-header-background my-4 mt-7 px-4">
										Maintenance Information <br />
										<span>
											<v-icon small>mdi-progress-question</v-icon> Specify the maintenance details for your
											sub-part
										</span>
									</p>
								</v-col>
							</v-row>
							<v-row class="px-4">
								<v-col md="4" class="my-auto py-0">
									<label for="cost-price" class="btx-label mt-2">Start Date</label>
								</v-col>
								<v-col md="8" class="py-0">
									<DatePicker
										hide-details
										clearable
										:disabled="pageLoading"
										:loading="pageLoading"
										id="maintenance-start-date"
										placeholder="Start Date"
										v-model="subpart.maintenance_start_date"
									></DatePicker>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label for="vendor" class="btx-label mt-2">End Date</label>
								</v-col>
								<v-col md="8" class="py-0">
									<DatePicker
										hide-details
										clearable
										:disabled="pageLoading"
										:loading="pageLoading"
										id="maintenance-end-date"
										placeholder="End Date"
										:min-date="subpart.maintenance_start_date"
										v-model="subpart.maintenance_end_date"
									></DatePicker>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label for="reminder-type" class="btx-label mt-2">Reminder Type</label>
								</v-col>
								<v-col md="8" class="py-0">
									<AutoCompleteInput
										dense
										id="reminder-type"
										hide-details
										v-model="subpart.maintenance_reminder_type"
										placeholder="Select Reminder Type"
										:items="reminder_type_items"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										item-value="value"
										v-on:keyup="get_manintaenaceReminder_values()"
									>
									</AutoCompleteInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label for="reminder-start-date" class="btx-label mt-2"
										><template v-if="subpart.maintenance_reminder_type != 1">Start </template>Date</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<DatePicker
										dense
										id="reminder-start-date"
										hide-details
										v-model="subpart.maintenance_reminder_date"
										:placeholder="
											subpart.maintenance_reminder_type != 1 ? 'Select Start Date...' : 'Select Date...'
										"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-on:keyup="get_manintaenaceReminder_values()"
										:min-date="formatDateRaw()"
									></DatePicker>
								</v-col>
								<template v-if="subpart.maintenance_reminder_type != 1">
									<v-col md="4" class="my-auto py-0">
										<label for="manintaenaceReminder-frequency" class="btx-label mt-2">Frequency</label>
									</v-col>
									<v-col md="8" class="py-0">
										<TextInput
											dense
											id="manintaenaceReminder-frequency"
											hide-details
											type="number"
											v-model="subpart.maintenance_reminder_frequency"
											placeholder="Enter Frequency..."
											:disabled="pageLoading"
											:loading="pageLoading"
											v-on:keyup="get_manintaenaceReminder_values()"
											class="mt-0"
										></TextInput>
									</v-col>
									<v-col md="4" class="my-auto py-0">
										<label for="manintaenaceReminder-end-date" class="btx-label mt-2">End Date</label>
									</v-col>
									<v-col md="8" class="py-0">
										<DatePicker
											dense
											id="reminder-end-date"
											hide-details
											v-model="subpart.manintaenace_reminder_end_date"
											placeholder="Select End Date..."
											:disabled="pageLoading"
											:loading="pageLoading"
											readonly
										></DatePicker>
									</v-col>
								</template>
								<v-col md="4" class="my-auto py-0">
									<label for="cost-price" class="btx-label mt-2">Maintenance Cost Price</label>
								</v-col>
								<v-col md="8" class="py-0">
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="cost-price"
										placeholder="Cost Price"
										v-model="subpart.maintenance_cost"
									>
									</PriceInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-performed-by-${uniqFieldId}`" class="btx-label mt-3"
										>Maintenance by</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<RadioInput
										row
										hide-details
										v-model="subpart.manintaenaceAsset_by"
										:disabled="pageLoading"
										:id="`service-performed-by-${uniqFieldId}`"
										:items="manintaenaceItems"
									></RadioInput>
								</v-col>

								<template v-if="subpart.manintaenaceAsset_by == 1">
									<v-col md="4" class="my-auto py-0">
										<label for="vendor" class="btx-label mt-2">Supplier</label>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="vendorList"
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`vendor-${uniqFieldId}`"
											placeholder="Supplier"
											v-model="subpart.maintenance_by"
										></AutoCompleteInput>
									</v-col>
								</template>
								<template v-else>
									<v-col md="4" class="my-auto py-0">
										<label :for="`member-${uniqFieldId}`" class="btx-label mt-3">Staff</label>
									</v-col>
									<v-col md="8" class="py-0">
										<AutoCompleteInput
											hide-details
											:items="memberList"
											:disabled="pageLoading"
											:loading="pageLoading"
											:id="`member-${uniqFieldId}`"
											placeholder="Staff"
											v-model="subpart.member"
										>
										</AutoCompleteInput>
									</v-col>
								</template>
								<v-col md="12" class="min-height-40"> </v-col>
							</v-row>
							<v-row>
								<v-col md="12" class="py-0">
									<p class="middle-header-background my-4 mt-7 px-4">
										Extended Information<br />
										<span>
											<v-icon small>mdi-progress-question</v-icon> Specify the purchase and location details
											for your sub part
										</span>
									</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-cost-${uniqFieldId}`" class="btx-label mt-3 required">Cost</label>
								</v-col>
								<v-col md="8" class="py-0">
									<PriceInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`service-cost-${uniqFieldId}`"
										placeholder="Cost"
										v-model="subpart.cost"
									></PriceInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3"
										>Manufacturing Date</label
									>
								</v-col>
								<v-col md="8" class="py-0">
									<DatePicker
										placeholder="Manufacturing Date"
										id="export-from-date"
										v-model="subpart.manufacturing_date"
									></DatePicker>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label :for="`service-from-${uniqFieldId}`" class="btx-label mt-3">Purchased Date</label>
								</v-col>
								<v-col md="8" class="py-0">
									<DatePicker
										placeholder="Purchased Date"
										id="export-from-date"
										v-model="subpart.purchased_date"
										:rules="[vrules.required(subpart.purchased_date, 'Purchased Date')]"
										:class="{
											required: !subpart.purchased_date,
										}"
									></DatePicker>
								</v-col>

								<v-col md="4" class="my-auto py-0">
									<label :for="`vendor-${uniqFieldId}`" class="btx-label mt-3">Supplier</label>
								</v-col>
								<v-col md="8" class="py-0">
									<AutoCompleteInput
										hide-details
										:items="vendorList"
										:disabled="pageLoading"
										:loading="pageLoading"
										:id="`vendor-${uniqFieldId}`"
										placeholder="Supplier"
										v-model="subpart.supplier"
									></AutoCompleteInput>
								</v-col>
								<v-col md="4" class="my-auto py-0">
									<label for="attachment" class="btx-label mt-2">Attachment</label>
								</v-col>
								<v-col md="8" class="py-0">
									<template v-for="(data, index) in subpart.attachment">
										<v-row :key="`file-row-${index}`" :class="{ 'table-alternate-row': index % 2 }">
											<v-col md="5" class="py-0">
												<v-file-input
													v-model="data.file"
													placeholder="Click here to select files"
													color="blue darken-4"
													multiple
													outlined
													class="mt-3"
													prepend-icon=""
													prepend-inner-icon="mdi-attachment"
													hide-details
													v-on:change="limitAttachment($event, index)"
												></v-file-input>
											</v-col>
											<v-col md="5" class="py-0">
												<TextInput hide-details placeholder="File Name" v-model="data.name"></TextInput>
											</v-col>
											<v-col md="1" class="py-0">
												<v-btn
													:disabled="subpart.attachment.length < 2"
													v-on:click="removeFile(index)"
													class="white--text mt-3"
													depressed
													color="red darken-4"
													tile
													style="margin-left: -10px"
												>
													<v-icon>mdi-delete</v-icon>
												</v-btn>
											</v-col>
											<v-col md="1" class="py-0">
												<v-btn
													:disabled="subpart.attachment.length > 2"
													v-on:click="addMore()"
													class="white--text mt-3"
													depressed
													color="blue darken-4"
													tile
													style="margin-left: -13px"
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</v-col>
										</v-row>
									</template>
								</v-col>
							</v-row>
						</v-col>
						<v-col md="4">
							<ImageUpload can-change v-model="subpart.image" :page-loading="pageLoading"></ImageUpload>
						</v-col>
					</v-row>
				</v-form>
			</template>
			<template v-slot:action>
				<v-btn
					v-if="subPartUuid"
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onUpdateSubmit()"
				>
					Update
				</v-btn>
				<v-btn
					v-else
					class="white--text"
					:loading="pageLoading"
					:disabled="!formValid || pageLoading"
					depressed
					color="blue darken-4"
					tile
					v-on:click="onSubmit()"
				>
					Add
				</v-btn>
				<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
			</template>
		</CreateDialog>
		<template v-if="manageCategoryDialog">
			<ManageCategory
				:dialog="manageCategoryDialog"
				:category="categoryList"
				v-on:close-dialog="manageCategoryDialog = false"
				v-on:success="getCategories"
			></ManageCategory>
		</template>
		<ManageSupplier
			:dialog="manageSupplierDialog"
			:category="categoryList"
			v-on:close-dialog="manageSupplierDialog = false"
			v-on:success="getCategories"
		></ManageSupplier>
		<ManageSerialNo
			:dialog="serialNoDialog"
			:serial-nos="categoryList"
			v-on:close-dialog="serialNoDialog = false"
			v-on:success="getCategories"
		></ManageSerialNo>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import ApiService from "@/core/services/api.service";
import { UploadFiles } from "@/core/lib/upload.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import PriceInput from "@/view/components/PriceInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import ImageUpload from "@/view/components/ImageUpload";

//import UploadFile from "@/view/components/UploadFile";
//import { GetLocationByInventory } from "@/core/lib/inventory.lib";
import { EventBus } from "@/core/event-bus/event.bus";
import CreateDialog from "@/view/components/CreateDialog";
import PhoneInput from "@/view/components/Phone";
import ObjectPath from "object-path";
import RadioInput from "@/view/components/RadioInput";
import NewRadioInput from "@/view/components/NewRadioInput";
import ManageCategory from "@/view/components/Manage-Category.vue";
import ManageSupplier from "@/view/components/Manage-Supplier.vue";
import ManageSerialNo from "@/view/components/ManageSerialNo.vue";
import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
import MomentJS from "moment-timezone";

export default {
	name: "subpart-create",
	title: "Sub Part Create",
	props: {
		type: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		endpoint: {
			type: String,
			default: null,
		},
		subPartDialog: {
			type: Boolean,
			default: false,
		},
		serviceArray: {
			type: Object,
			default: null,
		},
		typeData: {
			type: Object,
			default: null,
		},
	},
	watch: {
		subPartDialog(param) {
			if (param) {
				this.init();
			}
		},
		serviceArray(param) {
			if (param) {
				this.subpart = {
					type: param.type,
					from: param.actual_start_date,
					to: param.actual_end_date,
					cost: param.cost,
					expected_completion: param.expected_end_date,
					inventories: [],
					attachments: [],
					service_type: param.service_type,
					performed_by: param.service_by,
					location_by: param.location_by,
					indefinitely: param.indefinitely,
					vendor: param.supplier,
					member: param.member,
					description: param.description,
				};
				this.subPartUuid = param.uuid;
			}
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			serviceTypeList: [],
			vendorList: [],
			memberList: [],
			inventoryList: [],
			manageCategoryDialog: false,
			serialNoDialog: false,
			manageSupplierDialog: false,
			inventory: {},
			subPartUuid: null,
			subpart: {
				image: null,
				name: null,
				serial_nos: [{ serial_no: "test-456" }],
				cost: 0,
				quantity: 1,
				manufacturing_date: null,
				purchased_date: null,
				supplier: null,
				warranty: false,
				warranty_start_date: null,
				warranty_end_date: null,
				warranty_cost: 0,
				description: null,
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
				reminder_type: 1,
				reminder_start_date: null,
				reminder_frequency: null,
				reminder_end_date: null,
				category: null,
				fault: 1,
				maintenance_reminder_type: 1,
				warranty_type: 2,
				warranty_duration: 0,
				warranty_duration_type: "day",
			},
			reminder_type_items: [],
			categoryList: [],
			faultIteam: [
				{
					label: "Yes",
					value: 1,
				},
				{
					label: "No",
					value: 0,
				},
			],
			warrantyType: [
				{
					label: "Depends On Equipment",
					value: 2,
					description: "Warranty Depends on Asset",
				},
				{
					label: "Standalone",
					value: 1,
					description: "Add New Individual Warranty",
				},
			],
			manintaenaceItems: [
				{
					label: "Supplier",
					value: 1,
				},
				{
					label: "Staff",
					value: 2,
				},
			],
			wtx_attachments: [
				{
					file: null,
					url: null,
					name: null,
				},
			],
		};
	},
	components: {
		DatePicker,
		PriceInput,
		TextAreaInput,
		ImageUpload,
		AutoCompleteInput,
		ManageSerialNo,
		TextInput,
		PhoneInput,
		NumberInput,
		CreateDialog,
		RadioInput,
		NewRadioInput,
		SelectInput,
		ManageSupplier,
		ManageCategory,
	},
	methods: {
		addMoreWarranty() {
			this.wtx_attachments.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFileWarranty(index) {
			this.wtx_attachments.splice(index, 1);
			if (this.wtx_attachments.length < 1) {
				this.addMoreWarranty();
			}
		},
		limitAttachmentWarranty(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.wtx_attachments[index].url = ObjectPath.get(response, "0.path", null);
					this.wtx_attachments[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateDuration(e, type) {
			let warranty_start_date = e;
			if (type != "start_date") {
				warranty_start_date =
					this.warranty && this.warranty.warranty_start_date ? this.warranty.warranty_start_date : null;
			}
			if (warranty_start_date) {
				let { warranty_duration_type, warranty_duration } = this.subpart;
				let date = MomentJS(warranty_start_date, "YYYY-MM-DD");
				let duration = warranty_duration ? toSafeInteger(warranty_duration) : 0;
				switch (warranty_duration_type) {
					case "day":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "d");
						break;
					case "month":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "M");
						break;
					case "year":
						date = MomentJS(warranty_start_date, "YYYY-MM-DD").add(duration, "y");
						break;
				}
				/* this.$nextTick(() => {
					this.subpart.warranty_end_date = date.format("YYYY-MM-DD");
				}); */
				this.subpart.warranty_end_date = date.format("YYYY-MM-DD");
			}
		},
		checkWarrantyDiff(endDate) {
			if (this.subpart.warranty_start_date && endDate) {
				let startDate = MomentJS(this.subpart.warranty_start_date);
				let endData = MomentJS(endDate);
				let diffData = endData.diff(startDate, "days");
				this.subpart.warranty_duration_type = "day";
				this.subpart.warranty_duration = Number(diffData);
			}
		},
		getSubPartSetails(data) {
			this.subpart.category = data.group ? data.group : 0;
			this.subpart.purchased_date = data.purchased_on ? data.purchased_on : null;
			this.subpart.supplier = data.supplier ? data.supplier : 0;
			this.subpart.warranty = data.warranty_status ? data.warranty_status : false;
			this.subpart.warranty_start_date = data.warranty_start_date ? data.warranty_start_date : null;
			this.subpart.warranty_end_date = data.warranty_end_date ? data.warranty_end_date : null;

			this.subpart.reminder_type = data.warranty_reminder_type ? data.warranty_reminder_type : null;
			this.subpart.reminder_start_date = data.warranty_reminder_date
				? data.warranty_reminder_date
				: null;
			this.subpart.reminder_frequency = data.warranty_reminder_frequency
				? data.warranty_reminder_frequency
				: 0;
			this.subpart.reminder_end_date = data.warranty_reminder_endDate
				? data.warranty_reminder_endDate
				: null;
			this.subpart.fault = data.is_fault ? data.is_fault : 0;
			this.subpart.type_id = data.id ? data.id : 0;
			this.subpart.contact = data.contact ? data.contact : null;
			this.warrantyType = [
				{
					label: "Depends On Equipment",
					value: 2,
					description: `Warranty Depends on Asset ${data.barcode}`,
				},
				{
					label: "Standalone",
					value: 1,
					description: "Add New Individual Warranty",
				},
			];
			this.checkWarrantyDiff(this.subpart.warranty_end_date);
		},
		getCategories(data) {
			this.categoryList = data;
		},
		manageQuantity(data) {
			let preSerialData = this.subpart.serial_nos;
			let serialArray = [];
			for (let i = 0; i < data; i++) {
				if (preSerialData[i]) {
					serialArray.push(preSerialData[i]);
				} else {
					serialArray.push({ serial_no: null, status: true });
				}
			}
			this.subpart.serial_nos = serialArray;
		},
		addMore() {
			this.subpart.attachment.push({
				file: null,
				url: null,
				name: null,
			});
		},
		removeFile(index) {
			this.subpart.attachment.splice(index, 1);
			if (this.subpart.attachment.length < 1) {
				this.addMore();
			}
		},
		limitAttachment(output, index) {
			if (this.pageLoading) {
				return false;
			}

			const attachment = output.slice(0, 3);

			if (attachment.length <= 0) {
				return false;
			}

			const request = new FormData();

			for (let i = 0; i < attachment.length; i++) {
				request.append(`files[${i}]`, attachment[i]);
			}

			this.pageLoading = true;

			UploadFiles(request)
				.then((response) => {
					this.subpart.attachment[index].url = ObjectPath.get(response, "0.path", null);
					this.subpart.attachment[index].name = ObjectPath.get(response, "0.name", null);
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		init() {
			this.service = new Object({
				type: 1,
				from: null,
				to: null,
				cost: null,
				expected_completion: null,
				inventories: [],
				attachment: [
					{
						file: null,
						url: null,
						name: null,
					},
				],
				service_type: null,
				performed_by: 1,
				location_by: 1,
				indefinitely: 0,
				vendor: 0,
				member: 0,
				description: null,
			});
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.addPartForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.addPartForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			let requestData = this.subpart;
			requestData.type = this.type;
			(requestData.attachment_warranty = this.wtx_attachments),
				ApiService.post(`subpart`, requestData)
					.then(() => {
						this.$emit("success");
						EventBus.$emit("reload:asset-service", true);
					})
					.catch((error) => {
						this.$emit("error", error);
						this.logError(error);
					})
					.finally(() => {
						this.pageLoading = false;
					});
		},
		onUpdateSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.addPartForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.addPartForm.validate()) {
				return false;
			}

			this.pageLoading = true;

			ApiService.put(`subpart/${this.subPartUuid}`, this.service)
				.then(() => {
					this.$emit("success");
					EventBus.$emit("reload:asset-service", true);
				})
				.catch((error) => {
					this.$emit("error", error);
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		removeInventory(index) {
			let inventoryArr = this.subpart.inventories;
			inventoryArr.splice(index, 1);
			this.subpart.inventories = inventoryArr;
		},
		getServiceTypes(data) {
			this.serviceTypeList = data;
		},
	},
	mounted() {
		this.vendorList = this.localDB("suppliers", []);
		this.memberList = this.localDB("members", []);
		this.inventoryList = this.localDB("inventories", []);
		this.reminder_type_items = this.localDB("reminder_type_items", []);
		this.categoryList = this.localDB("groups", []);
		this.getSubPartSetails(this.typeData);
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 40);
		},
	},
};
</script>
